<template>
    <div>
        <div class="p-4 panel-head bg-gray-200 flex gap-3 justify-between items-center">
            <span class="text-gray-700 text-md font-medium text-lg">{{ activeFieldPanel.field.name || activeFieldPanel.field.label }} </span>
            <font-awesome-icon @click="$emit('handleClosePanel')" icon="times" class="cursor-pointer text-primary text-xl" />
        </div>
        <div class="p-3">
            <CheckGapValidationInput v-if="activeFieldPanel.field.use_in_gap_validation" :valid_period_prop="activeFieldPanel.field.valid_gap" :history_prop="activeFieldPanel.field.history" :showSave="isBlockedSaved" @onGapValidationPayload="handleGapValidationPayload($event)" :key="activeFieldPanel.field.id"/>
            <!-- <h4 class="text-gray-600 m-0 p-0 text-md font-medium">Visibility</h4> -->
            <div v-if="activeFieldPanel.field && activeFieldPanel.field.roles && activeFieldPanel.field.roles.length" class="py-3">
                <table class="min-w-full divide-y divide-gray-200 border border-solid border-gray-100 mb-2">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                            <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Read</th>
                            <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Write</th>
                            <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Visible</th>
                            <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Mandatory</th>
                        </tr>
                    </thead>
                    <tbody class="bg-card-bg divide-y divide-gray-200">
                        <tr v-for="(role, id) in activeFieldPanel.field.roles" :key="id" class="visibility_row">
                            <td class="px-2 py-1 text-sm flex items-center gap-2">
                                <Checkbox @input="$emit('handleSelectAllFieldPermission', role)" v-model="role.select_all" /> 
                                {{ role.title }}
                            </td>
                            <td class="px-1 py-2 text-center whitespace-nowrap">
                                <input :disabled="role.role_write || role.role_mandatory" @change="PermissionChange(role)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_read" />
                            </td>
                            <td class="px-1 py-2 text-center whitespace-nowrap">
                                <input :disabled="role.role_mandatory" @change="PermissionChange(role)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_write" />
                            </td>
                            <td class="px-1 py-2 text-center whitespace-nowrap">
                                <input :disabled="role.role_write || role.role_mandatory || role.role_read" @change="PermissionChange(role)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_visible" />
                            </td>
                            <td class="px-1 py-2 text-center whitespace-nowrap">
                                <input @change="PermissionChange(role)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_mandatory" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p v-else class="text-center py-5 text-gray-500">No Roles Found</p>

            <div v-if="activeFieldPanel.field && activeFieldPanel.field.roles && activeFieldPanel.field.roles.length" class="py-3">
                <table class="min-w-full divide-y divide-gray-200 border border-solid border-gray-100 mb-2">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Report Visibility</th>
                        </tr>
                    </thead>
                    <tbody class="bg-card-bg divide-y divide-gray-200">
                        <tr v-for="role in allReportsRoles" :key="role.id">
                            <td class="px-1" colspan="5">
                                <div class="form-control">
                                    <label class="label justify-start cursor-pointer gap-x-4">
                                        <input type="radio" class="flex-shrink-0 h-6 w-6 text-dvbrandcolor" :name="activeFieldPanel.field.id" :value="role.id" v-model="activeFieldPanel.field.report_visibility" />
                                        <span class="label-text">{{ role.name }}</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="isBlockedSaved" class="p-3 flex justify-end mt-6">
            <Button @click="updateFieldLabel(activeFieldPanel.section, activeFieldPanel.field)" text="Update Field" class="btn-primary" />
        </div>
        <template v-if="loadingState.getDependent">
            <div class="flex justify-center">
                <loader class="my-5" height="12px" width="12px" :loading="loadingState.addFields" />
            </div>
        </template>

        <DependantFields v-else-if="activeFieldPanel.is_cascaded && dependentsFields.length" @updateDependentsFieldLabel="$emit('updateDependentsFieldLabel', $event)" :allReportsRoles="allReportsRoles" :activeFieldPanel="activeFieldPanel" :fields="dependentsFields" :checkData="checkData" />
    </div>
</template>

<script>
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
const DependantFields = () => import("@/components/dependant-fields");
import loader from "@/components/loader";
const CheckGapValidationInput = () => import("../../checkGapValidationInput.vue");

export default {
    name: "expanded-panel",
    components: {
        Button,
        Checkbox,
        DependantFields,
        loader,
        CheckGapValidationInput,
    },
    props: {
        dependentsFields: {
            type: Array,
            default: () => [],
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        checkData: {
            type: Object,
            default: () => {},
        },
        activeFieldPanel: {
            type: Object,
            default: () => {},
        },
        loadingState: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        updateFieldLabel(section, field) {
            this.$emit("updateFieldLabel", { section, field });
        },
        handleGapValidationPayload(payload) {
            let { section, field } = this.activeFieldPanel;
            field = { ...field, ...payload };
            this.$emit("updateFieldLabel", { section, field });
        },
    },
    computed: {
        isBlockedSaved() {
            return this.activeFieldPanel.field && this.activeFieldPanel.field.roles && this.activeFieldPanel.field.roles.length && !this.activeFieldPanel.field.created_with_block;
        },
    },
};
</script>

<style></style>
